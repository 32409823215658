import $ from "jquery"

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDN-n7AKYEZcJ4DFLEPUVq-AYlsfFPGGyc",
    authDomain: "ganac-online.firebaseapp.com",
    projectId: "ganac-online",
    storageBucket: "ganac-online.appspot.com",
    messagingSenderId: "409395228360",
    appId: "1:409395228360:web:246520cccb6e41896c7ffd",
    measurementId: "G-61QMTEMMJ3"
  };
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// SANDBOX
// const ENV_openPayKey = "miqxlh9x4wbirxwxjpdq" ;
// const ENV_openPayPk = "pk_f38891ac9c764c4aa11b3b686f8fb9fd";
// const ENV_payment_url = "http://127.0.0.1:5001/ganac-online/us-central1/payment"
// const ENV_suscription_url = "http://127.0.0.1:5001/ganac-online/us-central1/suscription"
// const ENV_create_customer_url = "http://127.0.0.1:5001/ganac-online/us-central1/create_customer"

// PRODUCCIóN
const ENV_openPayKey = "mr5hloxsb61zxqsdarhh" ;
const ENV_openPayPk = "pk_91d2d505707b4e3bbf0c5777f5e247bf";
const ENV_payment_url = "https://payment-fdprejb7nq-uc.a.run.app"
const ENV_suscription_url = "https://suscription-fdprejb7nq-uc.a.run.app"
const ENV_create_customer_url = "https://create-customer-fdprejb7nq-uc.a.run.app"



let deviceSessionId;
let dataCreateToken = {};
let amount; // Cantidad a donar
let plan_id; // id de plan a suscripción
let angel_esperanza = false; // tipo de transacción
let lastCard;
let email;
let use_3d_secure = false;
let redirect_url = 'http://angeles.ganac.org.mx/success.html?success=true'


function buttonsEvent (event) {
    $('button').removeClass('selected');
    $(this).addClass('selected');
    event.preventDefault();
    amount = $(this).val();
    plan_id = $(this).attr('name');

    console.log(plan_id)

}

function payment_step1 (event){
    event.preventDefault();
    if ( $(event.target).val() == 'pay_angel') {
        angel_esperanza = true
        use_3d_secure = false

    } else {
        angel_esperanza = false
        use_3d_secure = true
    }

    console.log("TRANSACCIÓN ANGEL:"+angel_esperanza)
    let validateOk =  validateCardFormat()

    if(validateOk){
        $('.payment_flow').css('visibility', 'visible');
        $('.payment_flow').css('opacity', '100%');
        $('span#amount').html(amount);
        // $('#angelEsperanza').html(angel_esperanza);
        
        let ga_donativo = {
            currency: 'MXN',
            angel: angel_esperanza,
            value: amount, // Total Revenue
            items: [amount]
          };
        // validar campos vacios/faltantes
        logEvent(analytics, 'begin_checkout', ga_donativo);

    } else {
        console.log('algo salio mal al validar los datos')
    }
}

function payment_step2 (event) {

    uiControl('loading') // control ui

    // $(this).html(' <span class="material-symbols-outlined rotate" > sync </span><span>Estamos procesando tu solicitud... </span>')
    // $(this).css({ "background-color":"gray"  })
    $(this).prop('disabled', true)
    $('#field_step2').attr("disabled", true)

    event.preventDefault();

    let city =  $('#address-level2').val();
    let line3 = $('#address-level2').val();
    let postal_code = $('#postal-code').val();
    let line1 = $('#address-line1').val();
    let line2 = $('#address-line2').val();
    let state = $('#address-level1').val();
    email = $('#email').val();

    //VALIDAR LLENADO DE LOS DATOS

    dataCreateToken.address = {
        "city": city,
        "line3":line3,
        "postal_code": postal_code,
        "line1": line1,
        "line2":line2,
        "state":state,
        "country_code":"MX"
    }

    createSourceId()
}

function createSourceId () {
    console.log('create token step')
    OpenPay.token.create(dataCreateToken, onCreateSourceIdSuccess, onCreateSourceIdError)
}

function onCreateSourceIdError(err) {
    console.log(err)
    uiControl('back')
    uiControl_toast_err("datos incorrectos")
}

function onCreateSourceIdSuccess (response) {
    console.log(response)
    var chargeRequest = {
            "source_id": response.data.id,
            "device_session_id": deviceSessionId,
            "order_id": "",
            "amount": amount,
            "description": "Donativo Ganac",
            "use_3d_secure": use_3d_secure,
            "redirect_url" : redirect_url,
            "customer" : {
                "name" : response.data.card.holder_name,
                "last_name" : "",
                "phone_number" : "",
                "email" : email
            }
     }

    //  GA paso 2
    // Prepare ecommerce params
    const ga_donativo = {
        currency: 'MXN',
        value: amount, // Total Revenue
        payment_type: response.brand,
        items: [amount]
    };
    
    // Log event
    logEvent(analytics, 'add_payment_info', ga_donativo);

    if(angel_esperanza) {
        // enviar dara para suscripción
        console.log("preparar suscripción")
        createCustomerSuscription(chargeRequest)

    } else {
        createCharguer(chargeRequest)
    }
}

function createCustomerSuscription (data) {

    createCustomerCard(data)
        .then( (cardData)=>{
            console.log(cardData)
            if(cardData.id) {
                createSuscription(cardData)
                .then( suscription => responseChargeTransition(suscription) )
            } else {
                onCreateChargerError(cardData)
                return false
            }

        })
        

    // createCustomerCard(data)
    //     .then( cardData => createSuscription(cardData))
    //     .then( suscription => responseChargeTransition(suscription) )
}

let createCustomerCard = async (data) => {
    const rawResponse = await fetch(ENV_create_customer_url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      return await rawResponse.json();
}

let createSuscription = async (data) => {
   let suscription = {
    "customer_id": data.customer_id,
    "source_id": data.id,
    "plan_id": plan_id

}
    const rawResponse = await fetch(ENV_suscription_url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(suscription)
      })
      return await rawResponse.json();
}


let createCharguer = async (chargeRequest) => {
    const rawResponse = await fetch(ENV_payment_url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(chargeRequest)
    });

    
    // let responseCharge =  rawResponse;
    let responseCharge = await rawResponse.json();
    console.log(responseCharge)
    let estatus = responseCharge.status
    switch(estatus){
        case 'completed' :  responseChargeTransition(responseCharge);
        break;

        case 'charge_pending' : 
            console.log("pendiente, requiere 3dsecure");
            // set time para avisar al usuario
            window.location.replace(responseCharge.payment_method.url)

        break;

        default : onCreateChargerError(responseCharge);
        break;
    }

  }
  
function responseChargeTransition (responseTrasanction) {
    if(responseTrasanction.id) {
        uiControl('success', responseTrasanction )
        // medición
        let ga_donativo = {
            transaction_id: responseTrasanction.id,
            affiliation: 'Donativo Ganac',
            currency: 'MXN',
            angel: angel_esperanza,
            value: amount, // Total Revenue
            items: [amount]
          };

        logEvent(analytics, 'purchase', ga_donativo);
    } else {
        console.log(responseTrasanction)
        onCreateChargerError(responseTrasanction)
    }
}

function uiControl (screen, data) {

    console.log(data)
    // const template = {
    //     'loading' : '<h1>Un momento, Estamos procesando tu donativo</h1><p>Esto puede tardar unos minutos, no te desesperes tu ayuda es importante.</p><div><span class="material-symbols-outlined rotate" > sync </span><div>'
    //     ,
    //     'success': '<h1>¡Muchas gracias!</h1><p>Tu donativo nos ayuda a combatir el cáncer infantil.</p><p>Aún puedes seguir ayudando convirtiéndote en Ángel de Esperanza.</p> <p>Operación: <span id="operation_id">op</span><p>'
        
    // }
    let bgcolor;
    $('.payment_address').addClass('ui_control_hidden')
    $('.screen_section').removeClass('ui_control_hidden')
    $('.screen_section').addClass('ui_control_show')

    switch(screen) {
        case 'success': 
            bgcolor = '#8AD477';

            $('.loading').addClass('ui_control_hidden')
            $('.loading').removeClass('ui_control_show')

            $('.succes_donation').addClass('ui_control_show')
            $('.succes_donation').removeClass('ui_control_hidden')
            break;
            
        case 'loading' : 
            bgcolor = '#D9D9D9'; 
            $('.loading').addClass('ui_control_show')
            $('.loading').removeClass('ui_control_hidden')
            break;
        case 'back' :
            console.log('case back')
            // To payment address
            $('.payment_flow').css('background-color', '#9BC3CE');

            $('.payment_address').addClass('ui_control_show')
            $('.payment_address').removeClass('ui_control_hidden')

            // 
            $('.screen_section').css('color', '#484848');
            $('.screen_section').addClass('ui_control_hidden')
            $('.screen_section').removeClass('ui_control_show')

            // 
            $('.loading').addClass('ui_control_hidden')
            $('.loading').removeClass('ui_control_show')

            return false
        break;

        defaul :'';
        break;
    }
  
    $('.payment_flow').css('background-color', bgcolor);

// flow Angel

    // $('.screen_section').css('visibility', 'visible');
    // $('.screen_section').css('opacity', '100%');
    // $('.screen_section').css('display', 'flex');
    // $('.screen_section').css('color', '#484848');



    if(data) {
        $('#operation_id').html(JSON.stringify(data.id))
    }

}

function uiControl_toast_err (text_err) {

    $('#error_step2').css('opacity', '100%')
    $('#msg-error-pay').html(text_err)
    $('#pay_level2').css({ "background-color":"#BF2286"  });
    $('#pay_level2').html('Siguiente');
    $('#pay_level2').prop('disabled', false);
    $('#field_step2').attr("disabled", false);

}

// function reCharguerSecure () {
//     use_3d_secure = true; 
//     console.log("3d:"+use_3d_secure);
//     createSourceId();
// }

function onCreateChargerError(err) {
    //  Manejo de errores al crear el cargo.
    let msgErr;
    
    // if(err.error_code == 3005 && use_3d_secure == false) {

    //     // reCharguerSecure()
    //     // detectar categoria
    //     logEvent(analytics, 'error', {"error":err.error_code});

    // } else {
        switch (err.error_code) {
            case 3001: msgErr = "La tarjeta fue rechazada.";
            break;

            case 3002: msgErr="La tarjeta expiro.";
            break;

            case 3003: msgErr="La tarjeta fue declinada, intenta con otra tarjeta.";
            break;

            case 3004: msgErr="La tarjeta fue declinada, intenta con otra tarjeta.";
            break;

            case 3005: msgErr = "La tarjeta fue declinada, intenta con otra tarjeta."
            break;
            default: msgErr = 'No fue posible procesar tu donativo, verifica la información o intenta con otra tarjeta.';
            break
        }

        uiControl('back')
    // }

    uiControl_toast_err(msgErr)
}

function validateCardFormat () {
    let amount;
    let name = $("[name='cc-name']").val();
    let exp_year = $("[name='cc-exp']").val().substring(3,5);
    let exp_month = $("[name='cc-exp']").val().substring(0,2);
    let card = $("[name='cc-number']").val();
    let ccv = $("[name='cc-csc']").val()

    let validateCard = OpenPay.card.validateCardNumber(card);
    let validateExp = OpenPay.card.validateExpiry(exp_month, exp_year );
    let validateCcv = OpenPay.card.validateCVC( ccv, card );
    let status;

    // let banco = OpenPay.card.cardType(card)
    if(validateCard && validateExp && validateCcv) {
        
        lastCard = card.substring(15,19)
        $('span#card').html(lastCard)

        status = true
        dataCreateToken = {
            'card_number': card.replace(/\s+/g, ''),
            'holder_name': name,
            'expiration_year': exp_year,
            'expiration_month': exp_month,
            'cvv2':ccv
        }
        
    } else {
        status = false
        $('#erro_step1').css('opacity', '100%')
    }
    return(status)
}


String.prototype.toCardFormat = function () {
    return this.replace(/[^0-9]/g, "").substr(0, 16).split("").reduce(cardFormat, "");
    function cardFormat(str, l, i) {
        return str + ((!i || (i % 4)) ? "" : " ") + l;
    }
};



$(function(){


    console.log("USE 3DSECURE:" +  use_3d_secure)
    console.log("Tranzacción Angel:" + angel_esperanza)

    // set openpay
    OpenPay.setId(ENV_openPayKey);
    OpenPay.setApiKey(ENV_openPayPk);
    OpenPay.setSandboxMode(false);
    deviceSessionId = OpenPay.deviceData.setup();
    console.log(deviceSessionId)

    amount = $('.amount.selected').val()
    plan_id = $('.amount.selected').attr('name');

    let urlParameters = window.location.search;

    console.log(urlParameters)

    $('.cantidad button').on('click',  buttonsEvent );
    $('input').on('focus', (e)=> { $('.pay_error').css('opacity', '0')} )
    $('.btnPay').on('click', payment_step1 )
    $('#pay_level2').on('click', payment_step2  )
    $('#angel_no').on('click', ()=> {
        location.reload()
    } )

    $("[name='cc-number']").on( 'keyup',function () {
        $(this).val($(this).val().toCardFormat());
    });

 

})

